import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { theme } from 'theme';
import { PageContextType } from 'types';
import { AllWpPostType, WpPageType } from 'types/know-how-hub-page.d';

import Layout from 'components/templates/layout';
import HeadTitleDescriptionImage from 'components/organisms/head-title-description-image/head-title-description-image';
import PostsSectionRowSlider from 'components/organisms/posts-section-row-slider';
import LetsTalkBanner, { LetsTalkBannerType } from 'components/molecules/lets-talk-banner';
import Breadcrumb from 'components/atoms/breadcrumb/breadcrumb';

import useCurrentLanguage from 'hooks/use-current-language';

type DataProps = {
  commonComponents: {
    commonComponentsData: { letsTalkBanner: LetsTalkBannerType };
  };
  allWpKnowHow: AllWpPostType;
  knowHowPage: WpPageType;
};

export const KnowHowHubPage = ({ pageContext, data }: PageProps<DataProps, PageContextType>) => {
  const {
    knowHowPage: {
      template: {
        knowHowData: {
          bannerSimple: { heading, text },
          recentlyAddedHeading,
          readMoreLabel,
          postSectionList,
        },
      },
    },
    commonComponents: {
      commonComponentsData: { letsTalkBanner },
    },
    allWpKnowHow: { nodes: lastAdded },
  } = data;

  const currentLanguage = useCurrentLanguage();

  const filterPostsByLanguage = (posts) =>
    posts.filter((post) => {
      if (currentLanguage === 'EN') {
        return post.link.includes('/en/');
      }
      return !post.link.includes('/en/');
    });
  const filteredLastAdded = filterPostsByLanguage(lastAdded);

  const lastPost = filteredLastAdded[0];
  const lastPostInfo =
    lastPost.tags.nodes[0] && lastPost.categories.nodes[0]
      ? `${lastPost.tags.nodes[0].name}, ${lastPost.categories.nodes[0].name} - ${lastPost.date}`
      : ' ';

  const sections = [
    {
      heading: recentlyAddedHeading,
      posts: filteredLastAdded,
    },
    ...postSectionList,
  ];

  const marginPosts = (i) => {
    if (i === 0) {
      return `${theme.gap.xl} 0 0 0`;
    }
    if (i === 1) {
      return `${theme.gap.xl} 0 0 0`;
    }

    return `0`;
  };

  return (
    <Layout {...{ pageContext }}>
      <HeadTitleDescriptionImage
        titleTop={heading}
        subtitleTop={text}
        rowLayout
        description={lastPost.title}
        positionTitle="left"
        image={lastPost.featuredImage?.node}
        postInfo={lastPostInfo}
        linkButton={{
          icon: { name: 'arrow-right' },
          label: readMoreLabel,
          link: {
            ariaLabel: readMoreLabel,
            external: {
              url: lastPost.link,
            },
            internal: {
              uri: lastPost.link,
            },
          },
        }}
      >
        <Breadcrumb {...{ pageContext }} />
      </HeadTitleDescriptionImage>

      {sections.map((section, index) => (
        <PostsSectionRowSlider
          margin={marginPosts(index)}
          padding={`${theme.gap.mdl} 0 0 `}
          bgColor={index === 1 ? theme.colors.grayscaleGray0 : theme.colors.transparent}
          key={index}
          {...section}
        />
      ))}

      <LetsTalkBanner {...letsTalkBanner} margin={`${theme.gap.xl} auto 0`} halfBg />
    </Layout>
  );
};

export const query = graphql`
  query KnowHowHubPage($id: String, $commonComponentsSlug: String) {
    knowHowPage: wpPage(id: { eq: $id }) {
      id
      template {
        ... on WpTemplate_KnowHow {
          knowHowData {
            bannerSimple {
              text
              heading
            }
            recentlyAddedHeading
            readMoreLabel
            postSectionList {
              heading
              posts {
                ... on WpKnow_how {
                  id
                  title
                  link
                  date(formatString: "DD.MM.YYYY", locale: "PL")
                  categories {
                    nodes {
                      name
                      slug
                    }
                  }
                  tags {
                    nodes {
                      name
                      uri
                    }
                  }
                  featuredImage {
                    node {
                      ...WpImageFragment
                    }
                  }
                }
              }
              linkButton {
                link {
                  ariaLabel
                  external {
                    target
                    url
                  }
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                }
                icon {
                  name
                }
                label
              }
            }
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        letsTalkBanner {
          heading
          description
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
        }
      }
    }
    allWpKnowHow(sort: { order: DESC, fields: date }, limit: 20) {
      nodes {
        featuredImage {
          node {
            ...WpImageFragment
          }
        }
        date(formatString: "DD.MM.YYYY", locale: "PL")
        categories {
          nodes {
            name
            slug
          }
        }
        tags {
          nodes {
            name
            uri
          }
        }
        id
        link
        title
        tags {
          nodes {
            name
            uri
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
        uri
      }
    }
  }
`;

export default KnowHowHubPage;
